import DateFormatter from '../../../../../shared/js/date-formatter';
import AdminAvatar from './AdminAvatar';
import UserAvatar from './UserAvatar';

export function Message({
  strPrefix,
  shownName,
  strTime,
  avatar,
  strMessage,
  strDifferentUserClass,
  isLeft
}) {
  if (strMessage) {
    strMessage = strMessage.replace(/\n/gm, '<br>');
  }

  if (isLeft) {
    return (
      <div class="direct-chat-msg left">
        <div class="direct-chat-info clearfix">
          <span class="direct-chat-name pull-left">
            {strPrefix}
            {shownName}
          </span>
          <span class="direct-chat-timestamp pull-right">
            {DateFormatter(new Date(strTime), true)}
          </span>
        </div>

        {avatar !== undefined ? (
          <AdminAvatar avatar={avatar} alt={shownName} />
        ) : (
          <UserAvatar />
        )}
        <div
          class="direct-chat-text"
          dangerouslySetInnerHTML={{ __html: strMessage }}
        ></div>
      </div>
    );
  } else {
    return (
      <div class={`direct-chat-msg right ${strDifferentUserClass}`}>
        <div class="direct-chat-info clearfix">
          <span class="direct-chat-name pull-right">
            {strPrefix}
            {shownName}
          </span>
          <span class="direct-chat-timestamp pull-left">
            {DateFormatter(new Date(strTime), true)}
          </span>
        </div>

        {avatar !== undefined ? (
          <AdminAvatar avatar={avatar} alt={shownName} />
        ) : (
          <UserAvatar />
        )}
        <div
          class="direct-chat-text"
          dangerouslySetInnerHTML={{ __html: strMessage }}
        ></div>
      </div>
    );
  }
}
