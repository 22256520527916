import { Modal } from '.';

export default function SessionLostModal() {
  return new Modal({
    title: `Bitte Warten. Verbindung zum Server wird hergestellt.`,
    content:
      'Dies kann einige Sekunden dauern. Loggen Sie sich gerne erneut ein wenn die Verbindung nach mehr als 30 Sekunden nicht hergestellt wurde.',
    options: {
      showClose: false,
      showSpinner: true
    }
  });
}
