import { Modal } from '.';

export default function CookiesClearedModal() {
  return new Modal({
    title: 'Cookies wurden gelöscht. Bitte loggen Sie sich erneut ein.',
    content:
      'Dieser Fehler tritt auf wenn die Cookies extern gelöscht wurden. Bitte loggen Sie sich erneut ein um fortzufahren.',
    options: {
      showClose: false
    }
  });
}
