import { Component } from 'preact';
import { translator } from '../translation/index';

export class StatusSelect extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      selected: props.startValue
    };
  }

  render() {
    const className = this.props.values.find(
      (e) => e.value === this.state.selected
    ).className;
    return (
      <div>
        <span>{translator.t('chat.onlineStatus')}</span>
        <div class={this.state.open ? ' open' : ''} style={{ position: 'relative' }}>
          <button
            type="button"
            class="btn btn-default"
            aria-expanded={this.state.open ? 'true' : 'false'}
            onClick={() => this.setState({ open: !this.state.open })}
          >
            <span class={className}></span>
            <span class="caret"></span>
          </button>
          <ul class="dropdown-menu">
            {this.props.values.map((value) => (
              <li key={value.text}>
                <a
                  href="javascript:;"
                  onClick={() => {
                    this.setState({ open: false, selected: value.value }, () => {
                      this.props.onChange(value);
                    });
                  }}
                >
                  <span class={value.className}></span>
                  {value.text}
                </a>
              </li>
            ))}
          </ul>
        </div>
      </div>
    );
  }
}
