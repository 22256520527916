function updateLocalMessages(newMessages = [], localMessages = []) {
  localMessages.length = 0;
  console.log({ localMessages });

  for (const message of newMessages) {
    localMessages.push(message);
  }
}

module.exports = {
  updateLocalMessages
};
