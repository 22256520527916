// @ts-check

/**
 * @typedef {{ id: string, session: string, isLocal: boolean, isWs: boolean, number: number, unread: boolean, pageinfo: { name: string, url: string, device: 'L' | 'M' | 'S', country: string } }} User
 * @typedef {{avatar: string, message: string, self: boolean, senderName: string, shownName: string, time: Date, file?: { filename?: string, originalName?: string }}} Message
 * @typedef {{[userId: string]: { name: string, messages: Array<Message> }}} MessagesDictionary
 */

import * as Sentry from '@sentry/browser';
import { BrowserTracing } from '@sentry/tracing';
import 'admin-lte/bootstrap/js/bootstrap';
import 'admin-lte/dist/js/app';
import 'admin-lte/plugins/fastclick/fastclick';
import 'admin-lte/plugins/slimScroll/jquery.slimscroll';
import { EmojiConvertor } from 'emoji-js';
import habitat from 'preact-habitat';
import io from 'socket.io-client';
import tippy from 'tippy.js';
import Cookies from 'universal-cookie';
import Features from '../../../config/features';
import { saveMessage } from '../../../shared/js/save-message';
import { supportsJitsi, validateEmail } from '../../../shared/js/utils';
import { getNewVideo } from '../../../shared/js/videochat';
import { AVAILABILITY } from '../../enums/Availability';
import {
  appendFileToMessage,
  changeFavicon,
  destroyAvailability,
  disableForm,
  doBeep,
  enableForm,
  getContent,
  isAdmin,
  requestAvailability,
  requestVideoChat,
  sendMessage,
  showMessages,
  showNotification,
  updateAdminList,
  updateAvailability,
  updateTitle,
  updateUserList,
  uploadFile,
  uploadFileButtonFunc
} from './client_functions';
import { StatusSelect } from './components/status-select';
import { emailChatHistory } from './email-chat-history';
import { startHealthcheck } from './healthcheck';
import { Modal } from './modal';
import CookiesClearedModal from './modal/CookiesClearedModal';
import MailHistoryModal from './modal/MailHistoryModal';
import SessionLostModal from './modal/SessionLostModal';
import { translateSentences, translator } from './translation/index';
import onScheduledLogout from './utils/logout-timer';
import { updateLocalMessages } from './utils/messages';

Sentry.init({
  dsn: 'https://8f6bb5229c244026a69d37ca82185815@o4504049373151232.ingest.sentry.io/4504049378131968',
  integrations: [new BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
  environment: process.env.NODE_ENV
});

(function ($) {
  $(document).ready(() => {
    let sessionLostModal;
    let sessionLost = false;
    let session;
    const SUPPORTS_JITSI = supportsJitsi();

    startHealthcheck({
      pingCallback(params) {
        /**
         * this check means we are not fully connected yet, because
         * the session gets set in the `onLogin` callback
         */
        if (!session) return;

        /**
         * in case the pingCallback didn't return anything,
         * we can assume that we don't have a valid session
         */
        if (!params) {
          onSessionExpired(10001);
          return;
        }

        // TODO: Check this statement.
        // The request timed out, so we can assume that our session timed out
        if (params.reasonForFailure?.message === 'Request timed out') {
          onSessionExpired(10002);
          return;
        }

        if (params.reasonForFailure?.message === 'Network error') {
          onConnectionLost();
          return;
        }

        if (sessionLostModal) {
          sessionLost = false;
          sessionLostModal.destroy();
          sessionLostModal = null;
        }

        /**
         * If the cookie is not there, we can assume that they were deleted.
         */
        if (!document.cookie) {
          onCookiesCleared();
          return;
        }

        /**
         * If either the session that was returned by the server is not defined,
         * or doesn't match our current session, we can also assume that we don't have a valid session.
         * Often this happens when the server restarts, and the client is still connected.
         */
        if (params.session !== session) {
          onSessionExpired(10003);
          return;
        }

        Sentry.addBreadcrumb({
          category: 'console',
          message: 'Session recovered',
          level: 'info'
        });

        if (sessionLostModal) {
          sessionLostModal.destroy();
          sessionLostModal = undefined;
        }

        sessionLost = false;
      }
    });

    // @ts-ignore
    if (!window.__STATE) return;
    const cookies = new Cookies();

    const logoutAllSessionsAt = window.__STATE.logoutAllSessionsAt;

    if (logoutAllSessionsAt) {
      onScheduledLogout(logoutAllSessionsAt, () => {
        window.removeEventListener('beforeunload', beforeUnload);
        window.location.href = '/admin/logout';
      });
    }

    // @ts-ignore
    const socketServer = window.__STATE.socket_server;
    var originalPageTitle = document.title;

    // Define variables for loading content
    var login = '/login.html';
    var chat = '/chat.html';
    let chatTimeout;

    /**
     * Connect User To Socket Server as he is logged in.
     * @param {import("socket.io-client").Socket} _session
     */
    const onLogin = (_session) => {
      session = _session;

      const socket = io(socketServer, { transports: ['websocket'] });
      socket.on('connect', () => {
        console.log('Connected to socket server');

        if (sessionLost) {
          Sentry.captureMessage('Session lost and running connect function.');
          return;
        }
        onConnect(session, socket);
      });

      socket.on('deletedUser', () => {
        Sentry.captureMessage('Fired "deletedUser" event');
        window.removeEventListener('beforeunload', beforeUnload);
        window.location.href = '/admin/logout';
      });

      socket.on('connect_error', (err) => {
        Sentry.captureException(err);
        console.error(err);
      });

      socket.connect();

      setTimeout(() => console.info(`Socket status: ${socket.connected}`), 2000);
    };

    const onConnect = (session, socket) => {
      translateSentences();
      console.info('Running onConnect function');

      // Initializing settings and variables
      let currentDiscussionId;
      let strSendToName = '';
      let myGuestNumber = null;
      let strShownName = null;
      let strMyId = '';
      /** @type {MessagesDictionary} */
      const objMessages = {};
      let arrAdmins = [];
      /**
       * @type {Array<User>}
       */
      let arrUsers = [];
      let intUnreadMessages = 0;
      const objUnreadMessages = {};
      let intConnectedUsers = 0;
      /**
       * The session id of the user that the admin is currently talking to
       * @type {string}
       */
      let currentChat = '';
      let objUserTalkingTo = {};
      let windowFocus;
      var typing = false;
      var timeout = undefined;
      let availability = AVAILABILITY.BUSY;
      var objConstants = {
        strGuestPrefix: translator.t('chat.guest'),
        strAdminPrefix: 'Admin'
      };

      /** @type {HTMLElement | null} */
      const app = document.querySelector('.ws-chat-app');
      if (!app) return;

      /** @type {HTMLButtonElement | null} */
      const endChatButton = document.querySelector('.end-chat');
      if (!endChatButton) return;

      /** @type {HTMLButtonElement | null} */
      const mailHistoryButton = document.querySelector('.mail-history');
      if (!mailHistoryButton) return;

      const adjustAdminToolsVisibility = () => {
        const messagesExist = objMessages[currentChat] !== undefined;
        const lockedToMe = objUserTalkingTo[currentChat] === strMyId;
        const isUser = messagesExist && !isAdmin(objMessages[currentChat].name);

        endChatButton.classList.toggle('visible', messagesExist && lockedToMe && isUser);
        mailHistoryButton.classList.toggle(
          'visible',
          messagesExist && lockedToMe && isUser
        );
      };

      function updateUsers() {
        updateUserList({
          users: arrUsers
            .filter(({ id }) => {
              return objUserTalkingTo[id] !== strMyId;
            })
            .sort(({ id }) => {
              return objUserTalkingTo[id] !== undefined ? 1 : -1;
            }),
          ownSocketId: strMyId,
          objConstants: objConstants,
          objUserTalkingTo: objUserTalkingTo,
          availability: availability,
          onUserSelect: onUserClick,
          selectedChat: currentChat,
          selector: '.chat-tabs-left',
          numberSelector: '.number-users',
          noEntriesMessage: arrUsers.length
            ? translator.t('chat.noFurtherUserAvailable')
            : translator.t('chat.noUserAvailable')
        });
      }

      function updatePartners() {
        updateUserList({
          users: arrUsers.filter(({ id }) => {
            return objUserTalkingTo[id] === strMyId;
          }),
          ownSocketId: strMyId,
          objConstants: objConstants,
          objUserTalkingTo: objUserTalkingTo,
          availability: availability,
          onUserSelect: onUserClick,
          selectedChat: currentChat,
          selector: '.partner-tabs-left',
          numberSelector: '.number-partner',
          noEntriesMessage: translator.t('chat.noUserAssigned')
        });
      }

      const unselectChatPartner = () => {
        app.style.display = 'none';
        currentChat = '';

        updateUsers();
        updatePartners();
      };

      /**
       * @param {string} partner
       */
      const selectChatPartner = (partner) => {
        app.style.display = 'block';
        currentChat = partner;

        document.querySelector('.back')?.classList.remove('inactive-chat-partner');
        enableForm();
        updateUsers();
        updatePartners();

        const dmCount =
          document.querySelector('.direct-chat-messages')?.children.length || 0;
        currentDiscussionId = undefined;
        if (dmCount >= 1) return;

        fetch(`/admin/reconnect-chats`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ sessionId: currentChat })
        })
          .then((response) => {
            return response.json();
          })
          .then(({ result: discussion }) => {
            showMessages(
              currentChat,
              discussion.messagesReconnect,
              true,
              myGuestNumber,
              strSendToName
            );
            const savedChat = discussion.messagesReconnect[currentChat]?.messages;

            if (!objMessages[currentChat]) {
              objMessages[currentChat] = {
                messages: [],
                name: discussion.messagesReconnect?.[currentChat]?.name
              };
            }
            if (!objMessages[currentChat].messages) {
              objMessages[currentChat].messages = [];
            }

            updateLocalMessages(savedChat, objMessages[currentChat].messages);
            console.log('Saved chat', objMessages);
          })
          .catch((error) => {
            console.error(error);
          });
      };

      function onStatusChange(newStatus) {
        availability = newStatus.value;
        socket.emit('status:set', { session, availability });
      }

      window.addEventListener('beforeunload', beforeUnload);

      endChatButton.addEventListener('click', (e) => {
        e.preventDefault();

        socket.emit('discussion:unassign', { session: session, client: currentChat });

        var objInputEl = document.querySelector('.chatForm input[name="message"]');
        var objSubmitBtn = $('.chatForm .submitButton');
        objInputEl?.prop('disabled', true);
        objSubmitBtn.prop('disabled', true);

        var objBoxTitle = $('.ws-chat-app .box-header .box-title');
        objBoxTitle.text(`${translator.t('chat.selectChatPartner')}`);
        var objMessagesDiv = $('.direct-chat-messages');
        objMessagesDiv.html('');
        objMessagesDiv.append(
          `
            <div style="text-align: center; padding: 50px; font-size: 18px;">
              <p style="color:red;">Der Chat wurde beendet.</p>
              ${translator.t('chat.infoSelectChatPartner')}
            </div>
          `
        );
        unselectChatPartner();
        adjustAdminToolsVisibility();
      });

      mailHistoryButton.addEventListener('click', () => {
        const modal = MailHistoryModal({
          leftCallback() {
            mailHistoryButton.disabled = false;
            return false;
          },
          rightCallback() {
            /** @type {HTMLFormElement | null} */
            const formEl = document.querySelector('#email-history-form');
            if (!formEl) return false;

            const errors = formEl.querySelector('.errors');
            if (!errors) return false;

            // clear previous errors
            errors.innerHTML = '';

            const form = new FormData(formEl);

            const email = form.get('email')?.toString() || '';
            const comment = form.get('comment')?.toString() || '';
            const lang = form.get('lang')?.toString() || '';

            if (!validateEmail(email)) {
              errors.innerHTML = `${translator.t('modal.errorEmail')}`;
              return true;
            }

            emailChatHistory({
              client: currentChat,
              discussionId: currentDiscussionId,
              targetMail: email,
              comment: comment || undefined,
              lang
            })
              .then((response) => {
                mailHistoryButton.disabled = false;
                if (response && response.success) {
                  mailHistoryButton.classList.add('success');
                  setTimeout(() => {
                    mailHistoryButton.classList.remove('success');
                  }, 2000);
                } else {
                  console.error(response);
                }
              })
              .catch((e) => {
                mailHistoryButton.disabled = false;
                console.error(e);
              });

            return false;
          }
        });

        modal.show();
      });

      const selectAdminEvent = () => {
        $('.chat-tabs-right button').on('click', function () {
          const objInputEl = $('.chatForm input[name="message"]');
          var objSubmitBtn = $('.chatForm .submitButton');
          objInputEl.removeAttr('disabled');
          objSubmitBtn.removeAttr('disabled');
          var objMessagesDiv = $('.direct-chat-messages');
          objMessagesDiv.html('');
          strSendToName = $(this).data('nr');
          selectChatPartner($(this).data('sid'));
          var btn = $('.chat-tabs-right').find(`[data-sid='${currentChat}']`);
          btn.removeClass('btn-info');
          btn.addClass('btn-default');
          showMessages(currentChat, objMessages, true, myGuestNumber, strSendToName);

          if (typeof objUnreadMessages[currentChat] === 'undefined') {
            objUnreadMessages[currentChat] = 0;
          }

          intUnreadMessages = intUnreadMessages - objUnreadMessages[currentChat];
          objUnreadMessages[currentChat] = 0;

          intUnreadMessages = updateTitle({
            strTitle: originalPageTitle,
            intConnectedUsers: intConnectedUsers,
            intUnreadMessages: intUnreadMessages
          });

          adjustAdminToolsVisibility();
          $('#select-admin').hide();
        });
      };

      /**
       * @param {User} user
       */
      const onUserClick = (user) => {
        var objInputEl = $('.chatForm input[name="message"]');
        var objSubmitBtn = $('.chatForm .submitButton');
        objInputEl.removeAttr('disabled');
        objSubmitBtn.removeAttr('disabled');
        const objMessagesDiv = $('.direct-chat-messages');
        objMessagesDiv.html('');
        strSendToName = String(user.number);
        selectChatPartner(user.id);

        const chatPartnerOfSelectedUser = objUserTalkingTo[currentChat];
        if (
          chatPartnerOfSelectedUser === undefined ||
          chatPartnerOfSelectedUser === strMyId ||
          chatPartnerOfSelectedUser === ''
        ) {
          const user = arrUsers.find((aUser) => aUser.id === currentChat);

          if (user !== undefined) {
            user.unread = false;
          }

          updateUsers();
          updatePartners();

          showMessages(currentChat, objMessages, true, myGuestNumber, strSendToName);

          // we don't actually care who this is,
          // as long as we found an admin (not ourself) who is available
          const nonSelfAvailableAdmin = arrAdmins.find((admin) => {
            return admin.id !== strMyId && admin.available === AVAILABILITY.AVAILABLE;
          });

          if (nonSelfAvailableAdmin !== undefined && !isAdmin(strSendToName)) {
            $('#select-admin').show();
          } else {
            $('#select-admin').hide();
          }

          if (typeof objUnreadMessages[currentChat] === 'undefined') {
            objUnreadMessages[currentChat] = 0;
          }

          intUnreadMessages = intUnreadMessages - objUnreadMessages[currentChat];
          objUnreadMessages[currentChat] = 0;

          intUnreadMessages = updateTitle({
            strTitle: originalPageTitle,
            intConnectedUsers: intConnectedUsers,
            intUnreadMessages: intUnreadMessages
          });
        } else {
          updateUsers();
          updatePartners();

          // TODO: make this beautiful!
          alert('Die Zielperson befindet sich momentan in einem anderen Chat-Gespräch.');
          currentChat = '';
        }
        adjustAdminToolsVisibility();
      };

      // Requesting server to join chat
      socket.emit('join', session);

      socket.on('new-chat-request', ({ discussion, messages, userNumber }) => {
        if (window.isElectron) {
          const notifyText = `${translator.t('chat.guest')}#${userNumber}`;
          window.isElectron.send('show-notification', notifyText);
          window.isElectron.send('update-badge', 1);
        }

        showNotification({
          windowFocus: windowFocus,
          userName: `${translator.t('chat.guest')}#${userNumber}`
        });

        updateTitle({
          strTitle: 'Neue Chatanfrage',
          intConnectedUsers: intConnectedUsers,
          intUnreadMessages: intUnreadMessages
        });

        requestAvailability({
          discussion: discussion,
          userName: `${translator.t('chat.guest')}#${userNumber}`,
          messages: messages
        })
          .then((accept) => {
            socket.emit('new-chat-request-response', {
              session: session,
              discussion: discussion,
              response: accept
            });

            // when the admin has accepted the request,
            // mark the accepted users message as "unread"
            if (accept) {
              const user = arrUsers.find((aUser) => {
                return aUser.number === userNumber;
              });

              if (user !== undefined) {
                user.unread = true;
              }
            }

            // realistically, we only have to update the user list
            // when the admin accepted, but it can't hurt to always do it
            updateUsers();
            updatePartners();
          })
          .catch((err) => {
            console.error(err);
            socket.emit('new-chat-request-response', {
              session: session,
              discussion: discussion,
              response: false
            });
          });
      });

      socket.on('new-chat-request-expired', ({ discussion }) => {
        destroyAvailability({ discussion });
      });

      socket.on('new-chat-request-new-message', ({ discussion, messages }) => {
        updateAvailability({ discussion, messages });
      });

      // Receiving client info from server
      socket.on('yourData', (data) => {
        strMyId = data.id;
        myGuestNumber = data.number;
        strShownName = data.shownName;
        availability = data.availability;

        const { render } = habitat(StatusSelect);
        render({
          selector: '.status-select',
          defaultProps: {
            onChange: onStatusChange,
            startValue: data.availability,
            values: [
              {
                text: translator.t('chat.absent'),
                className: 'status status--busy',
                value: AVAILABILITY.BUSY
              },
              {
                text: translator.t('chat.available'),
                className: 'status status--available',
                value: AVAILABILITY.AVAILABLE
              }
            ]
          }
        });

        updateUsers();
        updatePartners();
      });

      // Update list of connected users
      socket.on('updateUser', (users) => {
        arrUsers = users.map((aNewUser) => {
          const oldUser = arrUsers.find((anOldUser) => {
            return anOldUser.session === aNewUser.session;
          });

          if (oldUser === undefined) {
            return { ...aNewUser, unread: false };
          }

          return { ...aNewUser, unread: oldUser.unread };
        });

        intConnectedUsers = users.length;
        intUnreadMessages = updateTitle({
          strTitle: originalPageTitle,
          intConnectedUsers: intConnectedUsers,
          intUnreadMessages: intUnreadMessages
        });

        updateUsers();
        updatePartners();

        // update favicon and do beep in case of missed messages
        changeFavicon(intUnreadMessages, '/Img/favicon_hint.ico');
        // doBeep(intUnreadMessages);
      });

      // When user refreshes the page or reconnects to socket.io
      socket.on('updateID', (oldId, newId) => {
        // If admin is currently talking to user that reconnected
        if (currentChat === oldId) {
          selectChatPartner(newId);
        }
        // Update local messages object to new socketID
        objMessages[newId] = objMessages[oldId];
        delete objMessages[oldId];

        // Update local talkingTo object to new SocketID
        objUserTalkingTo[newId] = objUserTalkingTo[oldId];
        delete objUserTalkingTo[oldId];

        // Update local objUnreadMessages to new SocketID
        if (typeof objUnreadMessages[oldId] !== 'undefined') {
          objUnreadMessages[newId] = objUnreadMessages[oldId];
          delete objUnreadMessages[oldId];
        }
      });

      /**
       * When admin connects, receive currentlyTalkingTo info
       * @param {any} data
       */
      function lockUpdateHandler(data) {
        objUserTalkingTo = data;

        updateUsers();
        updatePartners();

        adjustAdminToolsVisibility();
      }

      /**
       * When user gets locked to admin
       * @param {string} userSessionId
       * @param {string} adminSessionId
       */
      function onUserLock(userSessionId, adminSessionId) {
        objUserTalkingTo[userSessionId] = adminSessionId;

        const user = arrUsers.find((aUser) => aUser.id === userSessionId);

        if (user) user.unread = false;

        updateUsers();
        updatePartners();

        adjustAdminToolsVisibility();
      }

      /**
       * @param {any} data
       */
      function userLockedErrorHandler(data) {
        alert('Der User ist mittlerweile von einem anderen Admin kontaktiert worden.');

        objUserTalkingTo = data;

        updateUsers();
        updatePartners();
      }

      /**
       * Update list of connected admins
       * @param {Array<any>} admins
       */
      function adminsChangeHandler(admins) {
        arrAdmins = admins;
        updateAdminList({
          admins: admins,
          selfSocketId: strMyId,
          partnerSocketId: currentChat,
          partnerName: strSendToName,
          callback: selectAdminEvent
        });
      }

      // Client sends message
      $('.chatForm').off('submit');
      $(document).on('submit', '.chatForm', (e) => {
        e.preventDefault();
        e.stopPropagation();

        if (
          objUserTalkingTo[currentChat] === undefined ||
          objUserTalkingTo[currentChat] === strMyId ||
          objUserTalkingTo[currentChat] === ''
        ) {
          /** @type {HTMLInputElement | null} */
          const inputEl = document.querySelector('.chatForm [name="message"]');
          if (!inputEl) return;

          /** @type {HTMLInputElement | null} */
          const fileEl = document.querySelector('.chatForm [name="file"]');
          if (!fileEl) return;

          const emoji = new EmojiConvertor();
          // @ts-ignore
          emoji.init_env(); // else auto-detection will trigger when we first convert
          emoji.replace_mode = 'unified';
          emoji.allow_native = true;

          /** @type {string} */
          const messageRaw = inputEl.value.toString().trim();
          var message = emoji.replace_emoticons(messageRaw);

          const typingIndicator = document.querySelector('#typing');
          if (typingIndicator) typingIndicator.innerHTML = '';

          var file = fileEl.files?.[0];
          if (file === undefined) {
            sendMessage(
              {
                from: strMyId,
                fromName: myGuestNumber,
                to: currentChat,
                message
              },
              socket
            );
            inputEl.value = '';
            return;
          }

          var submitButton = document.querySelector('.chatForm .submitButton');
          submitButton?.setAttribute('disabled', 'disabled');

          uploadFile(file, ({ filename, error }) => {
            if (!error) {
              sendMessage(
                {
                  from: strMyId,
                  fromName: myGuestNumber,
                  to: currentChat,
                  message: message,
                  file: filename
                },
                socket
              );
              inputEl.value = '';
              fileEl.value = '';
              $('label[for="fileupload"]').removeClass('ws-active');
              $('label[for="fileupload"] span.filename').html('Datei auswählen...');
            } else {
              if (typingIndicator) {
                typingIndicator.innerHTML =
                  '<span style="color: #ff0000">Dateiformat nicht erlaubt!</span>';
              }
            }
            submitButton?.removeAttribute('disabled');
          });
        } else {
          // TODO: make this beautiful!
          alert('Die Zielperson befindet sich momentan in einem anderen Chat-Gespräch.');
        }
        return false;
      });

      // When chat history is handed over to another admin
      $(document).on('click', '#action-hand-over', (e) => {
        e.preventDefault();
        e.stopPropagation();

        const adminSocketId = $('#select-admin select').find(':selected').data('sid');

        // find the corresponding admin object
        const admin = arrAdmins.find((admin) => {
          return admin.id === adminSocketId;
        });

        if (admin === undefined) {
          return alert(
            'Es ist ein Fehler aufgetreten. Dieser Administrator konnte nicht ausgewählt werden.'
          );
        }

        if (admin.available !== AVAILABILITY.AVAILABLE) {
          return alert('Dieser Administrator ist momentan nicht verfügbar.');
        }

        // only hand over when messages exist
        if (typeof objMessages[currentChat] !== 'undefined') {
          socket.emit(
            'chatHistoryHandOver',
            session,
            adminSocketId,
            currentChat,
            objMessages[currentChat].name
          );

          // Reset local array because user was handed over to someone else
          // TODO: find a better way to do this
          objUserTalkingTo[currentChat] = adminSocketId;
          // Reset current chat
          unselectChatPartner();
        }
      });

      submitOnEnter();

      // On keypress tell server that user started typing
      // If user stops typing, after a brief delay, tell server
      $(document).on('keypress', '.chatForm input', (e) => {
        if (e.which == 13) {
          timeoutFunction();
        } else {
          if (
            typing === false &&
            currentChat !== '' &&
            $('.chatForm').find('[name="message"]').is(':focus')
          ) {
            typing = true;
            socket.emit('typing', {
              isTyping: true,
              strSendToId: currentChat,
              strName: strShownName,
              session: session
            });
          } else {
            clearTimeout(timeout);
          }
          timeout = setTimeout(timeoutFunction, 3000);
        }
      });

      /**
       * When admin receives chat history from another admin
       * @param {{ userId: string, data: MessagesDictionary[0] }} data
       */
      function receiveChatHandler(data) {
        showNotification({
          windowFocus: windowFocus,
          userName: `${translator.t('chat.guest')}#${data.data.name}`
        });

        const user = arrUsers.find((aUser) => {
          return aUser.id === data.userId;
        });

        if (user !== undefined) {
          user.unread = true;
        }

        updateUsers();
        updatePartners();

        updateTitle({
          strTitle: 'Neue Chatanfrage',
          intConnectedUsers: intConnectedUsers,
          intUnreadMessages: intUnreadMessages
        });

        objMessages[data.userId] = data.data;
        $('.modal').show();
        $('.modal .modal-title').text(`${translator.t('chat.handOver')}`);
        $('.modal .modal-body p').text(
          `${translator.t('chat.guest')}# ${data.data.name} ${translator.t(
            'chat.assignedText'
          )}`
        );
        $('button[data-dismiss="modal"]').hide();
        $('.modal-footer .btn-primary').text(`${translator.t('chat.understood')}`);
        $('.modal-footer .btn-primary').on('click', () => {
          $('.modal').hide();
        });
        objUserTalkingTo[data.userId] = strMyId;
      }

      // 'User is typing' functionality
      function timeoutFunction() {
        typing = false;
        socket.emit('typing', {
          isTyping: false,
          strSendToId: currentChat,
          strName: strShownName,
          session: session
        });
      }

      /**
       * When user starts typing, show notification
       * @param {Object} params
       * @param {boolean} params.isTyping
       * @param {string} params.id
       * @param {string} params.person
       */
      function typingHandler({ isTyping, id, person }) {
        if (isTyping && id === currentChat) {
          $('#typing').text(
            `${translator.t('chat.guest')}${person} ${translator.t('chat.writes')}`
          );
          if (chatTimeout) clearTimeout(chatTimeout);
          chatTimeout = setTimeout(() => {
            $('#typing').text('');
          }, 1500);
        } else {
          $('#typing').text('');
        }
      }

      /**
       * Client receives message
       * @param {Object} messageData
       * @param {string} messageData.from
       * @param {string} messageData.fromName
       * @param {string} messageData.shownName
       * @param {string} messageData.to
       * @param {string} messageData.time
       * @param {string} messageData.message
       * @param {{filename?: string}} messageData.file
       * @param {string} [messageData.fromAvatar]
       */
      function newMessageHandler(messageData) {
        const author = messageData.from;
        const strFromName = messageData.fromName;
        const shownName = messageData.shownName;
        const strTo = messageData.to;
        const date = new Date(messageData.time);
        let message = messageData.message;
        const file = messageData.file;
        const avatar = messageData.fromAvatar;

        if (file.filename !== undefined) {
          message = appendFileToMessage(file, message);
        }

        console.log('objMessages', objMessages);
        // SAVE MESSAGE LOCALLY

        // If message is mine
        if (author === strMyId) {
          saveMessage({
            target: strTo,
            messages: objMessages,
            message,
            date,
            self: true,
            sendToName: strSendToName,
            shownName,
            sendFromName: strFromName,
            avatar
          });
          adjustAdminToolsVisibility();
          showMessages(
            strTo,
            objMessages,
            true,
            myGuestNumber,
            shownName ? shownName : strSendToName
          );
          // If message isn't mine
        } else {
          saveMessage({
            target: author,
            messages: objMessages,
            message,
            date,
            self: false,
            sendToName: strFromName,
            shownName,
            sendFromName: strFromName,
            avatar
          });

          // If Chat Tab is active, load messages. Otherwise, create notifications
          if (currentChat === author) {
            showMessages(
              author,
              objMessages,
              true,
              myGuestNumber,
              shownName ? shownName : strSendToName
            );
          } else {
            const user = arrUsers.find((aUser) => {
              return aUser.id === author;
            });

            if (user !== undefined) {
              user.unread = true;
            }

            updateUsers();
            updatePartners();
          }

          if (!windowFocus || currentChat !== author) {
            intUnreadMessages++;

            if (typeof objUnreadMessages[author] !== 'undefined') {
              objUnreadMessages[author]++;
            } else {
              objUnreadMessages[author] = 1;
            }

            intUnreadMessages = updateTitle({
              strTitle: originalPageTitle,
              intConnectedUsers: intConnectedUsers,
              intUnreadMessages: intUnreadMessages
            });

            // if (window.isElectron) {
            //   const notifyText = `${translator.t('chat.guest')}#` + strFromName;
            //   window.isElectron.send('show-notification', notifyText);
            // }

            // update favicon and do beep in case of missed messages
            changeFavicon(intUnreadMessages, '/Img/favicon_hint.ico');
            doBeep(intUnreadMessages);
          }

          // generate notification if window is not in focus
          if (window.isElectron) {
            const notifyText = `${translator.t('chat.guest')}#${strFromName}`;
            window.isElectron.send('show-notification', notifyText);
            window.isElectron.send('update-badge', 1);
          } else if (
            Notification &&
            Notification.permission === 'granted' &&
            !windowFocus &&
            typeof message !== 'undefined'
          ) {
            const notification = new Notification('Neue Chat Nachricht', {
              // originally it was strFromName
              body: `${
                isAdmin(strFromName)
                  ? `${strShownName}`
                  : `${translator.t('chat.guest')}#${strFromName}`
              }: ${message}`,
              icon:
                avatar !== undefined
                  ? `/avatars/${avatar}`
                  : 'Img/avatar6_deskt_notif.png'
            });

            // when clicked on notification bring original window to focus
            notification.onclick = function () {
              window.focus();
              notification.close();
              let user = arrUsers.find((aUser) => {
                return aUser.id === author;
              });

              if (user === undefined) {
                user = arrAdmins.find((anAdmin) => {
                  return anAdmin.id === author;
                });
              }

              if (user !== undefined) {
                user.unread = false;

                const objInputEl = $('.chatForm input[name="message"]');
                const objSubmitBtn = $('.chatForm .submitButton');
                objInputEl.removeAttr('disabled');
                objSubmitBtn.removeAttr('disabled');
              }

              updateUsers();
              updatePartners();

              intUnreadMessages = 0;
              intUnreadMessages = updateTitle({
                strTitle: originalPageTitle,
                intConnectedUsers: intConnectedUsers,
                intUnreadMessages: intUnreadMessages
              });

              // update favicon and do beep in case of missed messages
              changeFavicon(intUnreadMessages, '/Img/favicon_hint.ico');
              doBeep(intUnreadMessages);

              selectChatPartner(author);
              showMessages(
                author,
                objMessages,
                true,
                myGuestNumber,
                shownName ? shownName : strSendToName
              );
            };

            // hide notification after 10 Seconds
            setTimeout(() => {
              notification.close();
            }, 10000);
          }
        }

        // always scroll new message to view
        $('.direct-chat-messages').scrollTop($('.direct-chat-messages')[0].scrollHeight);
      }

      /**
       * @param {Object} params
       * @param {string} params.sessionId
       * @param {string} params.discussionId
       */
      function onSessionDisconnectHandler({ sessionId, discussionId }) {
        console.info(`Session ${sessionId} disconnected.`);

        if (currentChat === sessionId) {
          disableForm();

          document.querySelector('.back')?.classList.add('inactive-chat-partner');

          currentDiscussionId = discussionId;
        }
      }

      function onNewVideoChatRequestHandler({ userName, userSession }) {
        requestVideoChat({
          userName: userName
        })
          .then((accept) => {
            if (accept) {
              sendMessage(
                {
                  from: strMyId,
                  fromName: myGuestNumber,
                  to: currentChat,
                  message: `Ihr Ansprechpartner hat ihre Video-Anfrage akzeptiert und tritt nun bei. `
                },
                socket
              );
              socket.emit('new-video-chat-request:accept', { session, userSession });
              sendMessage(
                {
                  from: strMyId,
                  fromName: myGuestNumber,
                  to: currentChat,
                  link:
                    `${Features.features.videoChatUrl}${session}--${userSession}` ||
                    `https://jitsi.ws-chat.de/${session}--${userSession}`,
                  type: 'videochat:admin',
                  message: `Der Video-Chat wird sich in einem neuem Fenster öffnen. Sollten Sie das Fenster ausversehen schließen oder es sich nicht öffnen, können Sie den Video-Chat hier erneut öffnen: ${
                    Features.features.videoChatUrl
                      ? Features.features.videoChatUrl
                      : 'https://jitsi.ws-chat.de/'
                  }${session}--${userSession}`
                },
                socket
              );
            }
          })
          .catch(console.error);
      }

      function onVideoChatStartHandler({ id }) {
        getNewVideo(id, io);
      }

      socket.on('lockUserInfo', lockUpdateHandler);
      socket.on('lockUser', onUserLock);
      socket.on('error:user-alreay-locked', userLockedErrorHandler);
      socket.on('updateAdmins', adminsChangeHandler);
      socket.on('sendMessage', newMessageHandler);
      socket.on('chatHistoryHandOverTarget', receiveChatHandler);
      socket.on('isTyping', typingHandler);
      socket.on('disconnect', onConnectionLost);
      socket.on('session:disconnect', onSessionDisconnectHandler);
      socket.on('new-video-chat-request', onNewVideoChatRequestHandler);
      socket.on('video-chat:start', onVideoChatStartHandler);

      // get Notification API from browser if not is already granted
      if ('Notification' in window && Notification.permission !== 'granted') {
        Notification.requestPermission();
      }

      // Set windowFocus on change
      window.addEventListener('focus', () => {
        windowFocus = true;

        if (currentChat === '') return;
        if (typeof objUnreadMessages[currentChat] === 'undefined') {
          objUnreadMessages[currentChat] = 0;
        }

        intUnreadMessages = intUnreadMessages - objUnreadMessages[currentChat];
        objUnreadMessages[currentChat] = 0;
        intUnreadMessages = updateTitle({
          strTitle: originalPageTitle,
          intConnectedUsers: intConnectedUsers,
          intUnreadMessages: intUnreadMessages
        });

        // update favicon and do beep in case of missed messages
        changeFavicon(intUnreadMessages, '/Img/favicon_hint.ico');
        // doBeep(intUnreadMessages);
      });
      window.addEventListener('blur', () => (windowFocus = false));

      const videoChatWrapper = document.querySelector('.videoChatWrapper');
      if (!SUPPORTS_JITSI && videoChatWrapper) {
        videoChatWrapper.classList.add('disabled');

        tippy(videoChatWrapper, {
          content:
            'Ihr Browser unterstützt diese Funktion nicht. Nutzen Sie bitte einen modernen Browser wie Chrome oder Firefox.'
          // to debug, use these props:
          // hideOnClick: false,
          // trigger: 'click'
        });
      }
    };

    /**
     * Warning message when admin is trying to reload/leave page
     */
    function beforeUnload(e) {
      var userAgent = navigator.userAgent.toLowerCase();
      if (userAgent.indexOf(' electron/') < 0) {
        const confirmationMessage = 'Chat-Verläufe gehen verloren, wenn Sie neu laden';

        (e || window.event).returnValue = confirmationMessage; // Gecko + IE
        return confirmationMessage; // Gecko + Webkit, Safari, Chrome etc.
      }
    }

    /**
     * Set Session to expired and show message with logout button.
     * @param {number} errorCode
     */
    function onSessionExpired(errorCode) {
      if (sessionLost) return;

      Sentry.addBreadcrumb({
        category: 'console',
        message: "We don't have a valid session.",
        level: 'warning'
      });

      Sentry.captureException(new Error(`Session Expired ${errorCode}`));

      sessionLost = true;
      sessionLostModal = new Modal({
        title: `Session abgelaufen (Error ${errorCode})`,
        content: `Ihre Session ist leider abgelaufen. Loggen Sie sich bitte erneut ein.`,
        options: {
          showClose: false,
          button: {
            right: {
              title: 'Zum Login',
              class: 'btn-primary',
              destroy: true,
              callback: () => {
                window.removeEventListener('beforeunload', beforeUnload);
                window.location.href = '/admin/logout';
                return false;
              }
            }
          }
        }
      });

      sessionLostModal.show();
    }

    function onConnectionLost() {
      if (sessionLost) return;

      doBeep(1);
      sessionLost = true;
      sessionLostModal = SessionLostModal();
      sessionLostModal.show();
    }

    function onCookiesCleared() {
      if (sessionLost) return;

      sessionLost = true;
      sessionLostModal = CookiesClearedModal();
      sessionLostModal.show();
    }

    /**
     * When login form is loaded
     */
    const loginButtonAction = () => {
      /** @type {HTMLInputElement | null} */
      const emailField = document.querySelector('#email');
      if (!emailField) return;

      /** @type {HTMLInputElement | null} */
      const passwordField = document.querySelector('#password');
      if (!passwordField) return;

      const emailFromCookie = cookies.get('email');

      if (emailFromCookie) {
        emailField.value = emailFromCookie;
      }

      $('#customForm').off('submit');
      $('#customForm').on('submit', (e) => {
        const email = emailField.value;
        const pass = passwordField.value;

        cookies.set('email', email, {
          expires: new Date(Date.now() + 1000 * 60 * 60 * 24 * 30)
        });

        if (typeof request !== 'undefined') request.abort();

        var request = $.ajax({
          method: 'POST',
          url: '/admin/login',
          data: {
            email: email,
            pass: pass,
            session: false
          },
          success: function (result) {
            if (result.loginStatus === '1') {
              const back = $('.back');
              getContent(chat, back, () => onLogin(result.session));
              $('.divider').show();
              $('.usermenu').show();
              $('.logout').show();
              $('.export').show();
              $('.archive').show();
              $('#card').addClass('flipped');
              $('#customLoginBox').fadeOut('fast');
            } else if (result.loginStatus == '2') {
              $('#customError').html(
                '<div class="callout callout-danger">Session existiert bereits als User.</div>'
              );
            } else if (result.loginStatus == '3') {
              // when session was destroyed and user is trying to log in without a session
              $('#customError').html(
                '<div class="callout callout-danger">Session Error. Diese Seite bitte neu laden.</div>'
              );
            } else {
              $('#customError').html(
                '<div class="callout callout-danger">Login ist fehlgeschlagen.</div>'
              );
            }
          }
        });
        e.preventDefault();
      });

      $('.ripple').on('click', function (event) {
        var $div = $('<div/>'),
          btnOffset = $(this).offset(),
          xPos = event.pageX - btnOffset.left,
          yPos = event.pageY - btnOffset.top;

        $div.addClass('ripple-effect');
        var $ripple = $('.ripple-effect');

        $ripple.css('height', $(this).height());
        $ripple.css('width', $(this).height());

        $div
          .css({
            top: yPos - $ripple.height() / 2,
            left: xPos - $ripple.width() / 2,
            background: $(this).data('ripple-color')
          })
          .appendTo($(this));

        window.setTimeout(() => {
          $div.remove();
        }, 2000);
      });
    };

    $.ajax({
      url: '/admin/remember',
      type: 'get',
      success(result) {
        const front = $('.front');
        if (result.loginStatus === '1') {
          getContent(chat, front, () => {
            onLogin(result.session);
          });
          $('.divider').show();
          $('.usermenu').show();
          $('.divider').show();
          $('.logout').show();
          $('.export').show();
          $('.archive').show();
          // $('#card').addClass('flipped');
          // $('#customLoginBox').fadeOut('fast');
        } else {
          getContent(login, front, loginButtonAction);
        }
      }
    });

    // Loader effect
    $(document).on({
      ajaxStart: function () {
        $('.login-box .overlay').removeClass('hidden');
      },
      ajaxStop: function () {
        $('.login-box .overlay').addClass('hidden');
        uploadFileButtonFunc();
      }
    });
  });

  // submit the chat message when hitting enter
  // but only, if shift was not pressed simultaneously
  const submitOnEnter = () => {
    /** @type {HTMLInputElement | null} */
    const inputElement = document.querySelector('.chatForm [name="message"]');

    inputElement?.addEventListener('keyup', (event) => {
      if (event.code === 'Enter' && !event.shiftKey) {
        $('.chatForm').submit();
        return;
      }
    });
  };
})(jQuery);
