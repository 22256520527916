import { translator } from '../../translation';
import { Message } from './Message';

export function appendFileToMessage(file, message) {
  const link = `<a style="color:#fff;text-decoration:underline;" download target="_blank" href="/files/${
    file.filename
  }"><svg width="35px" height="35px" xmlns="http://www.w3.org/2000/svg" viewBox="0 -256 1792 1792"><path d="M1407.339 503.271q17 41-14 70l-448 448q-18 19-45 19t-45-19l-448-448q-31-29-14-70 17-39 59-39h256v-448q0-26 19-45t45-19h256q26 0 45 19t19 45v448h256q42 0 59 39zm293 217q14 0 23 9t9 23v576q0 14-9 23t-23 9h-1600q-14 0-23-9t-9-23v-576q0-14 9-23t23-9h192q14 0 23 9t9 23v352h1152v-352q0-14 9-23t23-9h192z" fill="currentColor"/></svg><br>${
    file.originalName || file.filename
  }</a>`;

  return message.length > 0 ? `${link}<br><br>${message}` : link;
}

export function MessageList({ arrMessages, strMyName, isPartnerAdmin }) {
  return (
    <>
      {arrMessages.map((message) => {
        const boolSelf = message.self;
        const strTime = message.time;
        let strMessage = message.message;
        const strSenderName = message.senderName;
        const shownName = message.shownName;
        const avatar = message.avatar;
        const file = message.file;
        var strDifferentUserClass = '';
        let strPrefix = '';

        if (file?.filename) strMessage = appendFileToMessage(file, strMessage);

        if (boolSelf && strSenderName !== strMyName) {
          strDifferentUserClass = 'different-user';
        }

        if (!boolSelf) {
          if (!isPartnerAdmin) {
            strPrefix = `${translator.t('chat.guest')}#`;
            if (typeof shownName === 'string') {
              strPrefix = '';
            }
          }
          return (
            <Message
              strPrefix={strPrefix}
              shownName={shownName}
              strTime={strTime}
              avatar={avatar}
              strMessage={strMessage}
              isLeft={true}
            />
          );
        } else {
          return (
            <Message
              strPrefix={strPrefix}
              shownName={shownName}
              strTime={strTime}
              avatar={avatar}
              strMessage={strMessage}
              strDifferentUserClass={strDifferentUserClass}
              isLeft={false}
            />
          );
        }
      })}
    </>
  );
}
