import cron from 'cron';

export default function onScheduledLogout(cronString, cb) {
  if (!cronString) return;

  const cronJob = new cron.CronJob({
    cronTime: cronString,
    onTick: cb,
    start: true,
    timeZone: 'Europe/Paris'
  });

  return cronJob;
}
