import { Fragment } from 'preact';
import Flag from 'react-world-flags';
import { translator } from '../translation/index';

const deviceNames = {
  L: 'Desktop',
  M: 'Tablet',
  S: 'Smartphone'
};

export const PageInfo = ({ device, url, title, tabs, ad, country }) => {
  let deviceInfo = null;
  let adInfo = null;
  const visitorIconSrc = `../../Icons/${deviceNames[device]}.svg`;

  if (['L', 'M', 'S'].includes(device)) {
    deviceInfo = (
      <span title={`${deviceNames[device]} ${translator.t('chat.guest')}`}>
        <b>
          <img class="visitor-icon" src={visitorIconSrc} />
          &nbsp;&nbsp;|&nbsp;
        </b>
      </span>
    );
  }
  // check if URL contains URL Parameter in getAllAds
  if (ad) {
    adInfo = (
      <span
        title={`${translator.t('pageinfo.adText1')} ${ad.name} ${translator.t(
          'pageinfo.adText2'
        )}`}
      >
        <b>
          <img class="visitor-icon" src={`../../Icons/${ad.icon}`} />
          &nbsp;&nbsp;|&nbsp;
        </b>
      </span>
    );
  }

  let countryFlag = '';
  if (country !== null) {
    if (!country || country.length == 0) {
      countryFlag = (
        <span className="country-flag" title={`${translator.t('pageinfo.flagUnknown')}`}>
          🏳
        </span>
      );
    } else {
      countryFlag = (
        <Flag
          code={country}
          title={`${translator.t('pageinfo.flagAlt')} - ${country}`}
          fallback={<span className="country-flag">{country}</span>}
          className="country-flag"
        />
      );
    }

    countryFlag = (
      <fragment>
        {countryFlag}
        <b>&nbsp;&nbsp;|&nbsp;</b>
      </fragment>
    );
  }

  return (
    <Fragment>
      {adInfo != null && <Fragment>{adInfo} </Fragment>}
      {deviceInfo} {countryFlag}
      <span
        title={`${translator.t('pageinfo.tabsText1')} ${tabs} tab${
          tabs > 1 ? 's' : ''
        } ${translator.t('pageinfo.tabsText2')}`}
      >
        <b>
          {tabs} <img class="visitor-icon" src="../../Icons/tabs.svg" />
          &nbsp;
        </b>
      </span>{' '}
      auf{' '}
      <a href={url} title={url} target="_blank">
        {title}
      </a>
    </Fragment>
  );
};
