// @ts-check

import * as Sentry from '@sentry/browser';

/**
 * Wait for the given amount of seconds.
 * @param {number} seconds Number of seconds to wait
 * @returns {Promise<void>} A promise that resolves after the given number of seconds
 */
function wait(seconds) {
  return new Promise((resolve) => {
    setTimeout(resolve, seconds * 1000);
  });
}

/**
 * @param {Object} [options]
 * @param {(...any) => void} [options.pingCallback]
 */
export const startHealthcheck = (options = {}) => {
  var wasLastPingSuccessful = true;
  var hasConfig = typeof window.__healthcheck !== 'undefined';
  var isFE = hasConfig && __healthcheck.frontend;
  if (!options.pingCallback) {
    options.pingCallback = () => undefined;
  }

  const fetchWithTimeout = (url, timeout) => {
    const FETCH_TIMEOUT = timeout;
    let didTimeOut = false;

    return new Promise((resolve, reject) => {
      const timeout = setTimeout(() => {
        didTimeOut = true;
        reject(new Error('Request timed out'));
      }, FETCH_TIMEOUT);

      fetch(url, { headers: { 'Cache-Control': 'no-cache' } })
        .then((response) => {
          // Clear the timeout as cleanup
          clearTimeout(timeout);
          if (!didTimeOut) {
            resolve(response);
          }
        })
        .catch(async (err) => {
          // Clear the timeout as cleanup
          clearTimeout(timeout);

          console.error('Ping failed');
          Sentry.captureException(err);

          // Rejection already happened with setTimeout
          if (!didTimeOut) reject(new Error('Network error'));
        });
    });
  };

  function update(code) {
    const lastPing = wasLastPingSuccessful;
    wasLastPingSuccessful = code === 200;
    if (lastPing !== wasLastPingSuccessful) {
      /** @type {HTMLDivElement | null} */
      const typingIndicator = document.querySelector('#typing');
      /** @type {HTMLInputElement | null} */
      const chatInput = document.querySelector('#chatForm input.form-control');

      if (lastPing) {
        // we went from good -> bad
        if (isFE) {
          if (chatInput) chatInput.disabled = true;
          if (typingIndicator) typingIndicator.innerText = 'Momentan nicht erreichbar.';
        }
      } else {
        // we went from bad -> good
        if (isFE) {
          if (chatInput) chatInput.disabled = false;
          if (typingIndicator) typingIndicator.innerText = '';
        }
      }
    }
  }

  function ping() {
    const url = (hasConfig && __healthcheck.endpoint) || '/ping';

    fetchWithTimeout(url, 10000)
      .then((response) => {
        update(response?.status);

        try {
          response.json().then(options.pingCallback);
        } catch (err) {
          console.error(err);
          Sentry.captureException(err);
          options.pingCallback?.();
        }
        wait(5).then(ping);
      })
      .catch((err) => {
        Sentry.captureException(err);

        update();
        options.pingCallback?.({ reasonForFailure: err });
        wait(5).then(ping);
      });
  }

  ping();
};
