import { Fragment } from 'preact';
import { AVAILABILITY } from '../../../enums/Availability';
import { translator } from '../translation/index';
import { PageInfo } from './pageinfo';

/**
 * @typedef {import('../client.js').User} User
 */

let clickedChat = '';

/**
 * List of connected users
 * @param {Object} props
 * @param {Array<User>} props.users
 * @param {string} props.ownSocketId
 * @param {{[key: string]: any}} props.objConstants
 * @param {{[key: string]: any}} props.objUserTalkingTo
 * @param {typeof AVAILABILITY[keyof typeof AVAILABILITY]} props.availability
 * @param {(user: User) => void} props.onClick
 * @param {string} props.selectedChat The socket id of the selected user
 * @param {string} props.noEntriesMessage The socket id of the selected user
 */
export function UserList({
  users,
  ownSocketId,
  objConstants,
  objUserTalkingTo,
  availability,
  onClick,
  selectedChat,
  noEntriesMessage
}) {
  if (users.length < 1) {
    return (
      <div key="no-users" className="alert bg-light-blue">
        {noEntriesMessage}
      </div>
    );
  }

  const clearInput = () => {
    document.querySelector('.chatForm .form-control').value = '';
  };

  return (
    <Fragment>
      {users.map((user) => {
        // check if button has to be disabled because user is locked to someone else
        let disabled = false;
        const busy = availability !== AVAILABILITY.AVAILABLE;
        if (
          busy ||
          (objUserTalkingTo[user.id] !== undefined &&
            objUserTalkingTo[user.id] !== '' &&
            objUserTalkingTo[user.id] !== ownSocketId)
        ) {
          disabled = true;
        }

        const selected = selectedChat === user.id ? 'selected' : '';
        const selectedStyle =
          'color:#333;background-color:#d4d4d4;border-color:#8c8c8c;box-shadow:inset 0 3px 5px rgba(0,0,0,0.125);';

        return (
          <Fragment>
            <button
              class={`btn ${user.unread ? 'btn-info' : 'btn-default'} clearfix`}
              disabled={disabled}
              title={
                busy ? 'Bitte ändern Sie zunächst Ihren Online-Status zu Verfügbar.' : ''
              }
              data-nr={user.number}
              data-sid={user.id}
              style={`text-align:left;padding-left: 30px;${
                selected ? selectedStyle : ''
              }`}
              onClick={
                disabled
                  ? undefined
                  : (e) => {
                      if (clickedChat !== e.target.innerText) {
                        clearInput();
                      }
                      onClick(user);
                      clickedChat = e.target.innerText;
                    }
              }
            >
              <span
                class="status status--available"
                style="position: absolute; height: 10px; top: 12px; left: 12px;"
              ></span>
              <span style="float: left;">
                {user.isLocal ? '**' : user.isWs ? '*' : ''}
                {objConstants.strGuestPrefix}#{user.number}
              </span>
              {!selected && (
                <span style="float: right; color: #aaaaaa;">
                  {translator.t('chat.clickToChat')}
                </span>
              )}
            </button>
            <small class="pageinfo">
              {user.pageinfo && (
                <PageInfo
                  device={user.pageinfo.device}
                  url={user.pageinfo.url || user.pageinfo.location}
                  title={user.pageinfo.title || user.pageinfo.name}
                  tabs={user.pageinfo.numTabs || 0}
                  ad={user.pageinfo.adFrom || ''}
                  country={user.country}
                />
              )}
            </small>
          </Fragment>
        );
      })}
    </Fragment>
  );
}
