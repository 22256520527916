import { Modal } from '.';
import { translator } from '../translation';

export default function MailHistoryModal({ leftCallback, rightCallback }) {
  return new Modal({
    title: `${translator.t('modal.sendChatHistoryTitle')}`,
    content: `
            ${translator.t('modal.sendChatHistoryText')}<br /><br />

            <form id="email-history-form" onsubmit="event.preventDefault(); return false;">
              <div class="flag-pill">
              <input type="radio" id="ws-lang-en" name="lang" value="en">
                <label for="ws-lang-en">
                🇬🇧 EN
                </label>
                <input type="radio" id="ws-lang-de" name="lang" value="de">
                <label for="ws-lang-de">
                🇩🇪 DE
                </label>
              </div>
              <label>
                ${translator.t('useradministration.email')}<br />
                <input name="email" type="email" />
              </label>
              <br />
              <label style="display: block;">
              ${translator.t('modal.comment')} (optional)<br />
                <textarea name="comment" style="width: 100%;" rows="4"></textarea>
              </label>
              <br />
              <span class="errors"><span>
            </form>
          `,
    options: {
      showClose: false,
      button: {
        left: {
          title: translator.t('modal.cancelButton'),
          class: 'btn-default',
          destroy: true,
          callback: leftCallback
        },
        right: {
          title: translator.t('modal.sendButton'),
          class: 'btn-primary',
          destroy: true,
          callback: rightCallback
        }
      }
    }
  });
}
