export default function UserAvatar() {
  return (
    <svg
      class="userIcon"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      fill="#fff"
      width="35"
      height="35"
    >
      <defs>
        <clipPath id="circular-border">
          <circle cx="50%" cy="50%" r="41%"></circle>
        </clipPath>
      </defs>
      <circle cx="50%" cy="50%" r="46%" fill="black"></circle>
      <circle cx="50%" cy="38%" r="16%"></circle>
      <circle cx="50%" cy="92%" r="32%" clip-path="url(#circular-border)"></circle>
    </svg>
  );
}
