export default function AdminAvatar({ avatar, alt }) {
  return (
    <img
      class="userIcon"
      style="width: 35px; height: 35px; border-radius: 50%;"
      src={`/avatars/${avatar}`}
      alt={alt}
    />
  );
}
