import i18next from 'i18next';
import de from './locales/de.json';
import en from './locales/en.json';

i18next
  .init({
    lng: 'de',
    debug: true,
    resources: {
      en,
      de
    }
  })
  .then(() => {
    translateSentences();
  });

function translateSentences() {
  const allSentences = document.querySelectorAll('[data-i18n]');
  const allPlaceholders = document.querySelectorAll('[data-i18n-placeholder]');
  allSentences.forEach((elem) => {
    elem.innerHTML = i18next.t(elem.dataset.i18n);
  });
  allPlaceholders.forEach((elem) => {
    elem.placeholder = i18next.t(elem.dataset.i18nPlaceholder);
  });
}

export { i18next as translator, translateSentences };
